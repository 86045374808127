<template>
  <evalmee-chip
    v-bind="$attrs"
    v-on="$listeners"
    label
    :color="tag.color"
  >
    {{ tag.name }}
  </evalmee-chip>
</template>

<script>
import EvalmeeChip from "../../shared/evalmee_chip.vue"

export default {
  name: "QuestionTag",
  components: { EvalmeeChip },
  props: {
    tag: {
      type: Object,
      required: true,
    },
  },
}
</script>
